import CapillariesTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-redness/capillaries/CapillariesTemplate';

export default {
  title: 'Steps/Redness/Capillaries/CapillariesTemplate'
};

const createStory = props => () => ({
  components: { CapillariesTemplate },
  storyProps: props,
  wrapStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template: `<div :style='$options.wrapStyles'>
    <capillaries-template v-bind="$options.storyProps" />
  </div>`
});

export const Base = createStory({
  prominentBloodVessels: 'yes'
});
